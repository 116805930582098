<template>
    <v-main>
        <v-card color="#1f1f1f" class="py-3 px-5" flat fluid rounded="0">
            <v-row align="center" justify="space-between">
                <v-col cols="12" sm="6" class="text-left">
                    <img style="width: 200px; margin-top: 5px;" :src="$bucket + '/image/logo-duo.svg'" />
                </v-col>
                <v-col cols="12" sm="6" class="text-right">
                    <img style="width: 200px; " :src="$bucket + '/image/logo/logo-airnz-white_color.svg'" />
                </v-col>
            </v-row>
            <v-row align="center" justify="space-between" class="pb-4" style="border-bottom: 1px solid white">
                <v-col cols="6">
                    <h2 class="white--text" style="font-family: AirNZ Sans Semibold !important;">
                        {{ operation == "create" ? "New" : "Edit" }} {{ item == "slide" ? "slide" : "quiz question" }}
                    </h2>
                </v-col>
                <v-col cols="6" class="text-right">
                    <v-btn outlined class="mr-2 cancel-btn white--text" height="42" width="140" @click="cancel">Cancel</v-btn>
                    <v-btn color="#00C0F3" class="white--text" height="42" width="140" @click="showPreview(formItem)">Preview</v-btn>
                    <v-btn color="#01b0b9" class="white--text" height="42" width="140" @click="save">Save</v-btn>
                </v-col>
            </v-row>
            <v-row align="center" justify="space-between">
                <!-- Slide -->
                <v-card v-if="item == 'slide'" color="#1f1f1f" class="pt-4 pl-8" height="auto">
                    <v-row justify="start" class="py-6" style="width: 300px;">
                        <span style="color: white; font-family: AirNZ Sans Regular !important; font-size: 14px;">Slide layout</span>
                    </v-row>
                    <v-row justify="space-between" style="width: 600px;">

                        <!-- Slide 1 -->
                        <v-col cols="4" style="max-width: 180px;"
                            :style="formItem.layout == 1 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                            @click="formItem.layout = 1; formItem.type = 'new-training-1'; ">
                            <v-row class="justify-center" style="background-color: #221551; height: 52px;">
                                <v-icon size="50" color="#440099">mdi-image-area</v-icon>
                            </v-row>
                            <v-row style="background-color: #2d2926; height: 80px;">
                                <v-col cols="12" class="justify-center">
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 50px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 30px;">
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
    
                        <!-- Slide 2 -->
                        <v-col cols="4" style="max-width: 180px;"
                        :style="formItem.layout == 2 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                        @click="formItem.layout = 2; formItem.type = 'new-training-2'; ">
                            <v-row style="background-color: #2d2926; height: 82px;">
                                <v-col cols="12" class="justify-center">
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 50px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 30px;">
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="justify-center" style="background-color: #221551; height: 50px;">
                                <v-icon size="50" color="#440099">mdi-image-area</v-icon>
                            </v-row>
                        </v-col>

                        <!-- Slide 3 -->
                        <v-col cols="4" style="max-width: 180px;"
                        :style="formItem.layout == 3 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                        @click="formItem.layout = 3; formItem.type = 'new-training-3'; ">
                            <v-row class="justify-center" style="background-color: #221551; height: 60px;">
                                <v-icon size="50" color="#440099">mdi-image-area</v-icon>
                                <div style="margin-top: 29px; position: absolute;" class="d-flex justify-center">
                                <div
                                    style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; position: absolute; width: 100px;">
                                </div>
                                </div>
                            </v-row>
                            <v-row style="background-color: #2d2926; height: 72px;">
                                <v-col cols="12" class="justify-center">
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                        <div
                                        style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 30px;">
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="start" style="width: 600px;" class="pt-8">
                        <!-- Slide 4 -->
                        <v-col cols="4" style="max-width: 180px;"
                        :style="formItem.layout == 4 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                        @click="formItem.layout = 4; formItem.type = 'new-training-4'; ">
                            <v-row style="height: 132px;">
                                <v-col cols="6" align-self="center" style="background-color: #221551; height: 128px;">
                                    <div class="col justify-center align-center pl-1 mx-0">
                                        <v-icon size="60" color="#440099">mdi-image-area</v-icon>
                                    </div>
                                    </v-col>
                                    <v-col cols="6" align-self="center">
                                    <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                        <div
                                        style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 40px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                        <div
                                        style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 20px;">
                                        </div>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="1" style="max-width: 30px;"></v-col>

                        <!-- Slide 5 -->
                        <v-col cols="4" style="max-width: 180px;"
                        :style="formItem.layout == 5 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                        @click="formItem.layout = 5; formItem.type = 'new-training-5'; ">
                            <v-row style="height: 132px;">
                                <v-col cols="6" align-self="center">
                                    <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                        <div
                                        style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 40px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                        <div
                                        style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                                        </div>
                                    </div>
                                    <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                        <div
                                        style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 20px;">
                                        </div>
                                    </div>
                                    </v-col>
                                    <v-col cols="6" align-self="center" style="background-color: #221551; height: 128px;">
                                    <div class="col justify-center align-center pl-1 mx-0">
                                        <v-icon size="60" color="#440099">mdi-image-area</v-icon>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="start" style="width: 600px;" class="pt-8">
                        <!-- Slide 6 -->
                        <v-col cols="4" style="max-width: 180px;"
                        :style="formItem.layout == 6 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                        @click="formItem.layout = 6; formItem.type = 'new-training-6'; ">
                        <v-row style="height: 132px;">
                            <v-col cols="12" align-self="center">
                                <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                    <div
                                    style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 40px;">
                                    </div>
                                </div>
                                <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                    <div
                                    style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                                    </div>
                                </div>
                                <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                    <div
                                    style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                                    </div>
                                </div>
                                <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                    <div
                                    style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                                    </div>
                                </div>
                                <div style="margin-bottom: 8px;" class="d-flex justify-start">
                                    <div
                                    style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 20px;">
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        </v-col>

                        <v-col cols="1" style="max-width: 30px;"></v-col>

                        <!-- Slide 7 -->
                        <v-col cols="4" style="max-width: 180px;"
                        :style="formItem.layout == 7 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                        @click="formItem.layout = 7; formItem.type = 'new-training-7'; ">
                        <v-row style="height: 132px;">
                            <v-col cols="12" align-self="center">
                                <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                    <div
                                    style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 40px;">
                                    </div>
                                </div>
                                <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                    <div
                                    style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                                    </div>
                                </div>
                                <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                    <div
                                    style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                                    </div>
                                </div>
                                <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                    <div
                                    style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                                    </div>
                                </div>
                                <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                    <div
                                    style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 20px;">
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                        </v-col>
                    </v-row>
                    <v-row justify="start" class="pt-6" style="width: 300px;">
                        <div style="color: white; font-family: AirNZ Sans Regular !important; font-size: 14px;">Background Color</div>
                    </v-row>
                    <v-row justify="start" class="pt-2" style="width: 300px;">
                        <!-- Black circle with border -->
                        <div class="circle-black mr-3"
                        :style="formItem.backgroundColor == 'black' ? 'border: 2px solid #107d82 !important;' : 'border: 1px solid #5d5b5b !important;'"
                        @click="formItem.backgroundColor = 'black';"></div>
                        <!-- White circle with border -->
                        <div class="circle-white mr-3"
                        :style="formItem.backgroundColor == 'white' ? 'border: 2px solid #107d82 !important;' : 'border: 1px solid #5d5b5b !important;'"
                        @click="formItem.backgroundColor = 'white';"></div>
                        <!-- Purple circle with border -->
                        <div class="circle-purple"
                        :style="formItem.backgroundColor == 'purple' ? 'border: 2px solid #107d82 !important;' : 'border: 1px solid #5d5b5b !important;'"
                        @click="formItem.backgroundColor = 'purple';"></div>
                    </v-row>
                    <v-row justify="start" class="pt-6" style="width: 300px;">
                        <div style="color: white; font-family: AirNZ Sans Regular !important; font-size: 14px;">Hero Image</div>
                    </v-row>
                    <!-- //////////////////////////////////// -->
                    <v-row justify="start" class="pt-2" style="width: 700px;">
                        <v-col>
                            <v-text-field 
                                v-if="attachmentLabel.length > 0" 
                                v-model="attachmentLabel"
                                label="Upload Hero Image" 
                                prepend-icon="mdi-file" 
                                class="white--text"
                                @click="openFileDialog()" readonly dark/>
                            <v-text-field v-else 
                                v-model="formItem.imageName" 
                                label="Upload Hero Image" 
                                prepend-icon="mdi-file"
                                class="white--text"
                                @click="openFileDialog()" readonly dark/>
                            <input type="file" ref="attachment" 
                                accept="image/png, image/jpeg, image/jpg" 
                                style="display: none;"
                                @change="showImage" required />
                        </v-col>
                        <v-col cols="auto">
                            <v-icon color="white" 
                                class="mt-5 white--text"
                                @click="formItem.imageName = null; attachment = null; attachmentLabel = ''; refresh();">
                                    mdi-trash-can
                            </v-icon>
                        </v-col>
                    </v-row>
                    <div style="color: white; font-family: AirNZ Sans Regular !important; font-size: 12px; margin-top: -25px;">
                        It is recommended that image sizes not exceed 2MB.
                    </div>
                    <v-row justify="start" class="pt-2" style="width: 700px;">
                        <v-col>
                            <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="showImageLib('hero')">
                            Add Image from library
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="openFileDialog()">
                            Upload new image
                            </v-btn>
                        </v-col>
                    </v-row>
                    <!-- //////////////////////////// -->
                    <v-row>
                        <v-col cols="12" sm="12">
                        <p style="color: #BCBCBC; font-size: 12px;" class="mb-2">Heading</p>
                        <v-text-editor label="Heading" v-model="formItem.heading" :api-key="$tinyMceKey" :init="selectedInitEditor('Heading')"></v-text-editor>
                        <div class="mt-5">
                            <p style="color: #BCBCBC; font-size: 12px;" class="mb-2">Body Copy</p>
                            <v-text-editor class="mt-0 pt-0" label="Body Copy" v-model="formItem.bodyCopy" :api-key="$tinyMceKey" :init="selectedInitEditor('Body Copy')"></v-text-editor></div>
                        <v-text-field label="Button Text" v-model="formItem.buttonText" class="white--text mt-7 pt-0"
                            dark></v-text-field>
                        <v-checkbox v-model="formItem.isDisplayAfterQuiz" :value="false" label="Slide displays after quiz"
                            dark></v-checkbox>
                        </v-col>
                    </v-row>
                </v-card>
                <!-- QUIZ -->
                <v-card v-else color="#1f1f1f" class="pt-4 pl-8" height="auto">
                    <v-row justify="start" class="pt-6" style="width: 300px;">
                        <div style="color: white; font-family: AirNZ Sans Regular !important; font-size: 14px;">Background Color</div>
                    </v-row>
                    <v-row justify="start" class="pt-2" style="width: 300px;">
                        <!-- Black circle with border -->
                        <div class="circle-black mr-3"
                        :style="formItem.backgroundColor == 'black' ? 'border: 2px solid #107d82 !important;' : 'border: 1px solid #5d5b5b !important;'"
                        @click="changeBgColor('black')"></div>
                        <!-- White circle with border -->
                        <div class="circle-white mr-3"
                        :style="formItem.backgroundColor == 'white' ? 'border: 2px solid #107d82 !important;' : 'border: 1px solid #5d5b5b !important;'"
                        @click="changeBgColor('white')"></div>
                        <!-- Purple circle with border -->
                        <div class="circle-purple"
                        :style="formItem.backgroundColor == 'purple' ? 'border: 2px solid #107d82 !important;' : 'border: 1px solid #5d5b5b !important;'"
                        @click="changeBgColor('purple')"></div>
                    </v-row>
                    <v-row justify="start" class="py-6" style="width: 300px;">
                        <span style="color: white; font-family: AirNZ Sans Regular !important; font-size: 14px;">Question type</span>
                    </v-row>
                    <v-row justify="start" style="width: 600px;" class="pt-8">
                        <!-- Quiz 1 -->
                        <v-col cols="4" style="max-width: 180px;"
                        :style="formItem.layout == 1 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                        @click="formItem.layout = 1; formItem.type = 'new-quiz-1'; ">
                            <v-row class="justify-center" style="background-color: #221551; height: 60px;">
                                <v-icon size="50" color="#440099">mdi-image-area</v-icon>
                                <div style="margin-top: 23px; position: absolute;" class="d-flex justify-center">
                                <div
                                    style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; position: absolute; width: 100px;">
                                </div>
                                </div>
                                <div style="margin-top: 35px; position: absolute;" class="d-flex justify-center">
                                <div
                                    style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; position: absolute; width: 100px;">
                                </div>
                                </div>
                            </v-row>
                            <v-row style="background-color: #2d2926; height: 40px;">
                                <!-- <v-col v-for="n in 4" :key="n" cols="3"  class="justify-center"> -->
                                <v-col cols="2" class="d-flex align-start ml-1">
                                    <v-icon size="35" color="#440099">mdi-image-area</v-icon>
                                </v-col>
                                <v-col cols="2" v-for="n in 3" :key="n" class="ml-2">
                                    <v-icon size="35" color="#440099">mdi-image-area</v-icon>
                                </v-col>
                            </v-row>
                            <v-row style="background-color: #2d2926; height: 32px;">
                                <v-col class="d-flex align-end justify-end mr-2">
                                    <div
                                    style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 20px;">
                                    </div>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="1" style="max-width: 30px;"></v-col>

                        <!-- Quiz 2 -->
                        <v-col cols="4" style="max-width: 180px;"
                        :style="formItem.layout == 2 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                        @click="formItem.layout = 2; formItem.type = 'new-quiz-2';">
                        <v-row style="height: 132px;">
                            <v-col cols="12" align-self="center">
                            <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                <div
                                    style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 100px;">
                                </div>
                            </div>
                            <div style="margin-bottom: 8px;" class="d-flex justify-center">
                                <div
                                    style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 100px;">
                                </div>
                            </div>
                            <!--  -->
                            <div style="margin-bottom: 3px; margin-top: 25px;" class="d-flex justify-center">
                                <div
                                    class="d-flex justify-center"
                                    style="max-height: 7px; border: 7px solid #5e5d5d; background-color: #5e5d5d; height: 7px; width: 140px;">
                                    <div class="d-flex justify-center" style="margin-top:-3px; max-height: 6px; background-color: #2d2926; height: 6px; width: 140px;">
                                    </div>
                                </div>
                            </div>
                            <div style="margin-bottom: 3px;" class="d-flex justify-center">
                                <div
                                    class="d-flex justify-center"
                                    style="max-height: 7px; border: 7px solid #5e5d5d; background-color: #5e5d5d; height: 7px; width: 140px;">
                                    <div class="d-flex justify-center" style="margin-top:-3px; max-height: 6px; background-color: #2d2926; height: 6px; width: 140px;">
                                    </div>
                                </div>
                            </div>
                            <div style="margin-bottom: 3px;" class="d-flex justify-center">
                                <div
                                    class="d-flex justify-center"
                                    style="max-height: 7px; border: 7px solid #5e5d5d; background-color: #5e5d5d; height: 7px; width: 140px;">
                                    <div class="d-flex justify-center" style="margin-top:-3px; max-height: 6px; background-color: #2d2926; height: 6px; width: 140px;">
                                    </div>
                                </div>
                            </div>
                            <!--  -->
                            <div style="margin-top: 12px;" class="d-flex justify-end mr-2">
                                <div
                                style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 20px;">
                                </div>
                            </div>
                            </v-col>
                        </v-row>
                        </v-col>
                    </v-row>
                    <!-- QUIZ QUESTION -->
                    <v-row justify="start" class="py-6" style="width: 300px;">
                        <span style="color: white; font-family: AirNZ Sans Regular !important; font-size: 14px;">Question</span>
                    </v-row>
                    <v-row>
                        <v-col cols="12" sm="12">
                            <p style="color: #BCBCBC; font-size: 12px;" class="mb-2">Question</p>
                            <v-text-editor label="Question" v-model="formItem.question" :api-key="$tinyMceKey" :init="selectedInitEditor('Question')"></v-text-editor>
                        </v-col>
                    </v-row>

                    <!-- /////////////////Question/////////////////// -->
                    <v-row v-if="formItem.layout == 1" justify="start" class="pt-2" style="width: 700px;">
                        <v-col>
                            <v-text-field 
                                v-if="attachmentQuestionLabel.length > 0" 
                                v-model="attachmentQuestionLabel"
                                label="Upload Question Image" 
                                prepend-icon="mdi-file" 
                                class="white--text"
                                @click="openFileQuestionDialog()" readonly dark/>
                            <v-text-field v-else 
                                v-model="formItem.imageNameQuestion" 
                                label="Upload Question Image" 
                                prepend-icon="mdi-file"
                                class="white--text"
                                @click="openFileQuestionDialog()" readonly dark/>
                            <input type="file" ref="attachmentQuestion" 
                                accept="image/png, image/jpeg, image/jpg" 
                                style="display: none;"
                                @change="showImageQuestion" required />
                        </v-col>
                        <v-col cols="auto">
                            <v-icon color="white" 
                                class="mt-5 white--text"
                                @click="formItem.imageNameQuestion = null; attachmentQuestion = null; attachmentQuestionLabel = ''; refresh();">
                                    mdi-trash-can
                            </v-icon>
                        </v-col>
                    </v-row>
                    <div style="color: white; font-family: AirNZ Sans Regular !important; font-size: 12px; margin-top: -25px; margin-bottom: 10px">
                        It is recommended that image sizes not exceed 2MB.
                    </div>
                    <v-row v-if="formItem.layout == 1" justify="start" style="width: 700px;">
                        <v-col>
                            <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="showImageLib('question')">
                            Add Image from library
                            </v-btn>
                        </v-col>
                        <v-col>
                            <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="openFileQuestionDialog()">
                            Upload new image
                            </v-btn>
                        </v-col>
                    </v-row>
                    <!-- //////////////////////////// -->

                    <!-- QUIZ ANSWERS -->
                    <v-row justify="start" class="py-6" style="width: 300px;">
                        <span style="color: white; font-family: AirNZ Sans Regular !important; font-size: 14px;">Answers</span>
                    </v-row>
                    <!-- ANSWER 1 -->
                    <v-row>
                        <v-col cols="12" sm="12">
                            <p style="color: #BCBCBC; font-size: 12px;" class="mb-2">Answer 1</p>
                            <v-text-editor label="Answer 1" v-model="formItem.answer1" :api-key="$tinyMceKey" :init="selectedInitEditor('Answer 1')"></v-text-editor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="formItem.layout == 1">

                            <!-- /////////////////Answer 1/////////////////// -->
                            <v-row justify="start" class="pt-2" style="width: 100%;">
                                <v-col cols="8">
                                    <v-text-field 
                                        v-if="attachmentAnswer1Label.length > 0" 
                                        v-model="attachmentAnswer1Label"
                                        label="Upload Answer 1 Image" 
                                        prepend-icon="mdi-file" 
                                        class="white--text"
                                        @click="openFileAnswer1Dialog()" readonly dark/>
                                    <v-text-field v-else 
                                        v-model="formItem.imageNameAnswer1" 
                                        label="Upload Answer 1 Image" 
                                        prepend-icon="mdi-file"
                                        class="white--text"
                                        @click="openFileAnswer1Dialog()" readonly dark/>
                                    <input type="file" ref="attachmentAnswer1" 
                                        accept="image/png, image/jpeg, image/jpg" 
                                        style="display: none;"
                                        @change="showImageAnswer1" required />
                                </v-col>
                                <v-col cols="1">
                                    <v-icon color="white" 
                                        class="mt-5 white--text"
                                        @click="formItem.imageNameAnswer1 = null; attachmentAnswer1 = null; attachmentAnswer1Label = ''; refresh();">
                                            mdi-trash-can
                                    </v-icon>
                                </v-col>
                                <v-col cols="3">
                                    <v-checkbox v-model="formItem.correctAnswer" value="1" label="Correct answer"
                                        dark></v-checkbox>
                                </v-col>
                            </v-row>
                            <div style="color: white; font-family: AirNZ Sans Regular !important; font-size: 12px; margin-top: -25px; margin-bottom: 15px">
                                It is recommended that image sizes not exceed 2MB.
                            </div>
                            <v-row justify="start" class="pb-4" style="width: 700px; margin-top:-20px">
                                <v-col>
                                    <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="showImageLib('answer1')">
                                    Add Image from library
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="openFileAnswer1Dialog()">
                                    Upload new image
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <!-- //////////////////////////// -->

                        </v-col>
                        <v-col v-if="formItem.layout==2">
                            <v-checkbox v-model="formItem.correctAnswer" value="1" label="Correct answer"
                                style="margin-top: -15px;"
                                dark></v-checkbox>
                        </v-col>
                    </v-row>
                    <!-- ANSWER 2 -->
                    <v-row>
                        <v-col cols="12" sm="12">
                            <p style="color: #BCBCBC; font-size: 12px;" class="mb-2">Answer 2</p>
                            <v-text-editor label="Answer 2" v-model="formItem.answer2" :api-key="$tinyMceKey" :init="selectedInitEditor('Answer 2')"></v-text-editor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="formItem.layout == 1">

                            <!-- /////////////////Answer 2/////////////////// -->
                            <v-row justify="start" class="pt-2" style="width: 100%;">
                                <v-col cols="8">
                                    <v-text-field 
                                        v-if="attachmentAnswer2Label.length > 0" 
                                        v-model="attachmentAnswer2Label"
                                        label="Upload Answer 2 Image" 
                                        prepend-icon="mdi-file" 
                                        class="white--text"
                                        @click="openFileAnswer2Dialog()" readonly dark/>
                                    <v-text-field v-else 
                                        v-model="formItem.imageNameAnswer2" 
                                        label="Upload Answer 2 Image" 
                                        prepend-icon="mdi-file"
                                        class="white--text"
                                        @click="openFileAnswer2Dialog()" readonly dark/>
                                    <input type="file" ref="attachmentAnswer2" 
                                        accept="image/png, image/jpeg, image/jpg" 
                                        style="display: none;"
                                        @change="showImageAnswer2" required />
                                </v-col>
                                <v-col cols="1">
                                    <v-icon color="white" 
                                        class="mt-5 white--text"
                                        @click="formItem.imageNameAnswer2 = null; attachmentAnswer2 = null; attachmentAnswer2Label = ''; refresh();">
                                            mdi-trash-can
                                    </v-icon>
                                </v-col>
                                <v-col cols="3">
                                    <v-checkbox v-model="formItem.correctAnswer" value="2" label="Correct answer"
                                        dark></v-checkbox>
                                </v-col>
                            </v-row>
                            <div style="color: white; font-family: AirNZ Sans Regular !important; font-size: 12px; margin-top: -25px; margin-bottom: 15px">
                                It is recommended that image sizes not exceed 2MB.
                            </div>
                            <v-row justify="start" class="pb-4" style="width: 700px; margin-top:-20px">
                                <v-col>
                                    <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="showImageLib('answer2')">
                                    Add Image from library
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="openFileAnswer2Dialog()">
                                    Upload new image
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <!-- //////////////////////////// -->

                        </v-col>
                        <v-col v-if="formItem.layout==2">
                            <v-checkbox v-model="formItem.correctAnswer" value="2" label="Correct answer"
                                style="margin-top: -15px;"
                                dark></v-checkbox>
                        </v-col>
                    </v-row>
                    <!-- ANSWER 3 -->
                    <v-row>
                        <v-col cols="12" sm="12">
                            <p style="color: #BCBCBC; font-size: 12px;" class="mb-2">Answer 3</p>
                            <v-text-editor label="Answer 3" v-model="formItem.answer3" :api-key="$tinyMceKey" :init="selectedInitEditor('Answer 3')"></v-text-editor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="formItem.layout == 1">

                            <!-- /////////////////Answer 3/////////////////// -->
                            <v-row justify="start" class="pt-2" style="width: 100%;">
                                <v-col cols="8">
                                    <v-text-field 
                                        v-if="attachmentAnswer3Label.length > 0" 
                                        v-model="attachmentAnswer3Label"
                                        label="Upload Answer 3 Image" 
                                        prepend-icon="mdi-file" 
                                        class="white--text"
                                        @click="openFileAnswer3Dialog()" readonly dark/>
                                    <v-text-field v-else 
                                        v-model="formItem.imageNameAnswer3" 
                                        label="Upload Answer 3 Image" 
                                        prepend-icon="mdi-file"
                                        class="white--text"
                                        @click="openFileAnswer3Dialog()" readonly dark/>
                                    <input type="file" ref="attachmentAnswer3" 
                                        accept="image/png, image/jpeg, image/jpg" 
                                        style="display: none;"
                                        @change="showImageAnswer3" required />
                                </v-col>
                                <v-col cols="1">
                                    <v-icon color="white" 
                                        class="mt-5 white--text"
                                        @click="formItem.imageNameAnswer3 = null; attachmentAnswer3 = null; attachmentAnswer3Label = ''; refresh();">
                                            mdi-trash-can
                                    </v-icon>
                                </v-col>
                                <v-col cols="3">
                                    <v-checkbox v-model="formItem.correctAnswer" value="3" label="Correct answer"
                                        dark></v-checkbox>
                                </v-col>
                            </v-row>
                            <div style="color: white; font-family: AirNZ Sans Regular !important; font-size: 12px; margin-top: -25px; margin-bottom: 15px">
                                It is recommended that image sizes not exceed 2MB.
                            </div>
                            <v-row justify="start" class="pb-4" style="width: 700px; margin-top:-20px">
                                <v-col>
                                    <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="showImageLib('answer3')">
                                    Add Image from library
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="openFileAnswer3Dialog()">
                                    Upload new image
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <!-- //////////////////////////// -->

                        </v-col>
                        <v-col v-if="formItem.layout==2">
                            <v-checkbox v-model="formItem.correctAnswer" value="3" label="Correct answer"
                                style="margin-top: -15px;"
                                dark></v-checkbox>
                        </v-col>
                    </v-row>
                    <!-- ANSWER 4 -->
                    <v-row>
                        <v-col cols="12" sm="12">
                            <p style="color: #BCBCBC; font-size: 12px;" class="mb-2">Answer 4</p>
                            <v-text-editor label="Answer 4" v-model="formItem.answer4" :api-key="$tinyMceKey" :init="selectedInitEditor('Answer 4')"></v-text-editor>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col v-if="formItem.layout == 1">

                            <!-- /////////////////Answer 4/////////////////// -->
                            <v-row justify="start" class="pt-2" style="width: 100%;">
                                <v-col>
                                    <v-text-field 
                                        v-if="attachmentAnswer4Label.length > 0" 
                                        v-model="attachmentAnswer4Label"
                                        label="Upload Answer 4 Image" 
                                        prepend-icon="mdi-file" 
                                        class="white--text"
                                        @click="openFileAnswer4Dialog()" readonly dark/>
                                    <v-text-field v-else 
                                        v-model="formItem.imageNameAnswer4" 
                                        label="Upload Answer 4 Image" 
                                        prepend-icon="mdi-file"
                                        class="white--text"
                                        @click="openFileAnswer4Dialog()" readonly dark/>
                                    <input type="file" ref="attachmentAnswer4" 
                                        accept="image/png, image/jpeg, image/jpg" 
                                        style="display: none;"
                                        @change="showImageAnswer4" required />
                                </v-col>
                                <v-col cols="1">
                                    <v-icon color="white" 
                                        class="mt-5 white--text"
                                        @click="formItem.imageNameAnswer4 = null; attachmentAnswer4 = null; attachmentAnswer4Label = ''; refresh();">
                                            mdi-trash-can
                                    </v-icon>
                                </v-col>
                                <v-col cols="3">
                                    <v-checkbox v-model="formItem.correctAnswer" value="4" label="Correct answer"
                                        dark></v-checkbox>
                                </v-col>
                            </v-row>
                            <div style="color: white; font-family: AirNZ Sans Regular !important; font-size: 12px; margin-top: -25px; margin-bottom: 15px">
                                It is recommended that image sizes not exceed 2MB.
                            </div>
                            <v-row justify="start" class="pb-4" style="width: 700px; margin-top:-20px">
                                <v-col>
                                    <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="showImageLib('answer4')">
                                    Add Image from library
                                    </v-btn>
                                </v-col>
                                <v-col>
                                    <v-btn outlined class="mr-4 white--text" height="45" width="200" @click="openFileAnswer4Dialog()">
                                    Upload new image
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <!-- //////////////////////////// -->

                        </v-col>
                        <v-col v-if="formItem.layout==2">
                            <v-checkbox v-model="formItem.correctAnswer" value="4" label="Correct answer"
                                style="margin-top: -15px;"
                                dark></v-checkbox>
                        </v-col>
                    </v-row>
                    


                </v-card>
            </v-row>
        </v-card>

        <dialog-images-picker :show="dialogLibrary.show" :list="dialogLibrary.list" @onClickImage="onSelectImage"
            @onCloseDialog="dialogLibrary.show = false" />

        <v-dialog v-model="dialogPreview" persistent no-click-animation fullscreen hide-overlay scrollable transition="dialog-bottom-transition" @keydown.esc="closePreview()">
			<v-card height="100%" width="100%" rounded="0">
				<v-card-text class="fill-height pa-0 ma-0" style="background-color: #F8F8F8;">
					<v-row class="d-flex pt-3 pr-3">
						<v-col cols="12" class="d-flex justify-end">
							<v-btn icon @click="closePreview()">
								<v-icon large>mdi-close</v-icon>
							</v-btn>
						</v-col>
					</v-row>
					<v-container fluid style="margin-top: -1.5vw;">
                        <component ref="trainingModule" :is="'page-' + page.type" 
                            v-bind:page="page" 
                            v-bind:isPreview="true"
                            v-bind:bgColor="page.valueMap.backgroundColor"
                            v-bind:imageName="page.valueMap.imageName"

                            v-bind:headerTitle="page.valueMap.headerTitle"
                            v-bind:bodyCopy="page.valueMap.bodyCopy"
                            v-bind:buttonText="page.valueMap.buttonText"
                            
                            v-bind:question="page.valueMap.question"
                            v-bind:imageNameQuestion="page.valueMap.imageNameQuestion"
                            v-bind:answer1="page.valueMap.answer1"
                            v-bind:answer2="page.valueMap.answer2"
                            v-bind:answer3="page.valueMap.answer3"
                            v-bind:answer4="page.valueMap.answer4"
                            v-bind:imageNameAnswer1="page.valueMap.imageNameAnswer1"
                            v-bind:imageNameAnswer2="page.valueMap.imageNameAnswer2"
                            v-bind:imageNameAnswer3="page.valueMap.imageNameAnswer3"
                            v-bind:imageNameAnswer4="page.valueMap.imageNameAnswer4"
                            v-bind:correctAnswer="page.valueMap.correctAnswer"

                            :is-correct="isCorrect"
                            :is-selected="isSelected"
                            />
					</v-container>
				</v-card-text>
			</v-card>
		</v-dialog>
    </v-main>
</template>

<script>
import newTraining1 from "../trainingModulePageNew1.vue";
import newTraining2 from "../trainingModulePageNew2.vue";
import newTraining3 from "../trainingModulePageNew3.vue";
import newTraining4 from "../trainingModulePageNew4.vue";
import newTraining5 from "../trainingModulePageNew5.vue";
import newTraining6 from "../trainingModulePageNew6.vue";
import newTraining7 from "../trainingModulePageNew7.vue";
import newquiz1 from "../quizModulePageNew1.vue";
import newquiz2 from "../quizModulePageNew2.vue";

import dialogImagesPicker from "../dialog/dialogImagesPicker.vue"
import axios from "axios";
import Editor from "@tinymce/tinymce-vue";
/* eslint-disable no-console */
export default {
    components: {
        dialogImagesPicker,
        "v-text-editor": Editor,
        "page-new-training-1": newTraining1,
		"page-new-training-2": newTraining2,
		"page-new-training-3": newTraining3,
		"page-new-training-4": newTraining4,
		"page-new-training-5": newTraining5,
		"page-new-training-6": newTraining6,
		"page-new-training-7": newTraining7,

		"page-new-quiz-1": newquiz1,
		"page-new-quiz-2": newquiz2,
    },
    data: function() {
        return {

            pageImageList: [],
            attachment: null,
            attachmentLabel: "",

            attachmentQuestion: null,
            attachmentQuestionLabel: "",

            attachmentAnswer1: null,
            attachmentAnswer1Label: "",

            attachmentAnswer2: null,
            attachmentAnswer2Label: "",
            
            attachmentAnswer3: null,
            attachmentAnswer3Label: "",

            attachmentAnswer4: null,
            attachmentAnswer4Label: "",

            attachment1: null,
            attachment1Label: "",

            operation: "edit",
            item: "quiz",
            formItem : {
                layout: 1,
                backgroundColor: 'black',
                type: null,

                heading: null,
                bodyCopy: null,
                buttonText: null,
                isDisplayAfterQuiz: false,
                imageName: null,

                question: null,
                imageNameQuestion: null,
                answer1: null,
                answer2: null,
                answer3: null,
                answer4: null,
                imageNameAnswer1: null,
                imageNameAnswer2: null,
                imageNameAnswer3: null,
                imageNameAnswer4: null,
                correctAnswer: 0
            },
            openUploadNewImage: false,
            openAddImageFromLibrary: false,
            dialogLibrary: {
                show: false,
                target: '',
                list: [],
                idx: undefined,
            },
            attachment2: null,

            dialogPreview: false,
            page: {
				valueMap: {
					headerTitle: "",
					headerBackgroundColor: {
						// grey
						hexa: '#424242'
					},
					headerIconColor: {
						// white
						hexa: '#FFFFFFFF'
					},
					headerIconBackgroundColor: {
						// purple
						hexa: '#221551'
					},
					bodySummary: "",
					bodyTip: "",
					bodyNote: "",
					bodyIconTextColor: {
						// white
						hexa: '#FFFFFFFF'
					},
					bodyIconBackgroundColor: {
						// purple
						hexa: '#440099'
					},
					bodyQuestionMultipleChoice: {},
					bodyQuestionShortAnswer: {},
					footerHasBackButton: false,
					footerButtonConfig: ""
				}
			},
            initEditor: {
					height: 150,
					width: '100%',
					selector: '',
					menubar: false,
					plugins: ["code", "paste", "table", "lists", "link", "textcolor" ],
					paste_as_text: true,
					fontsize_formats: "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 31px 32px 33px 34px 35px 36px",
					font_formats: "Arial=arial,helvetica,sans-serif; \
                                    Courier New=courier new,courier,monospace; \
                                    Georgia=georgia,palatino; \
                                    Tahoma=tahoma,arial,helvetica,sans-serif; \
                                    Times New Roman=times new roman,times; \
                                    Verdana=verdana,geneva;",
                    toolbar:
							"code undo redo | fontsizeselect | fontselect | bold italic underline forecolor backcolor | table | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | link | removeformat | pastetext | help"
			},

            isCorrect: false,
            isSelected: false,
        }
    },
    created() {
		this.operation = this.$route.params.operation;
        this.item = this.$route.params.item;
	},
    mounted: function() {

        this.formItem.type = this.item == "slide" ? "new-training-1" : "new-quiz-1"

        if (this.operation == "edit" ) {

            // check if vuex slides exist
            let choosenItem = {...this.$store.state.choosenItem}

            if (this.item == "slide") {

                this.formItem.layout = choosenItem.layout
                this.formItem.backgroundColor = choosenItem.backgroundColor
                this.formItem.heading = choosenItem.heading
                this.formItem.bodyCopy = choosenItem.bodyCopy
                this.formItem.buttonText = choosenItem.buttonText
                this.formItem.isDisplayAfterQuiz = choosenItem.isDisplayAfterQuiz
                this.formItem.type = choosenItem.type
                this.formItem.imageName = choosenItem.imageName

            } else {    // quiz

                this.formItem.layout = choosenItem.layout
                this.formItem.backgroundColor = choosenItem.backgroundColor
                this.formItem.type = choosenItem.type

                this.formItem.question = choosenItem.question
                this.formItem.imageNameQuestion = choosenItem.imageNameQuestion
                this.formItem.answer1 = choosenItem.answer1
                this.formItem.answer2 = choosenItem.answer2
                this.formItem.answer3 = choosenItem.answer3
                this.formItem.answer4 = choosenItem.answer4
                this.formItem.imageNameAnswer1 = choosenItem.imageNameAnswer1
                this.formItem.imageNameAnswer2 = choosenItem.imageNameAnswer2
                this.formItem.imageNameAnswer3 = choosenItem.imageNameAnswer3
                this.formItem.imageNameAnswer4 = choosenItem.imageNameAnswer4
                this.formItem.correctAnswer = choosenItem.correctAnswer

            }

            this.formItem.title = choosenItem.title

        }

        this.getImageLib();
        
	},
    methods: {
        selectedInitEditor(label) {
			// field Body Copy with tinymce -> height = 400px, else 160px
			if (label && (label.includes('Body Copy'))) {
				return this.$root.copyWith({ 
                    height: 400, 
                    toolbar: 'undo redo | fontsizeselect fontselect | bold italic underline | forecolor backcolor removeformat | table | alignleft aligncenter alignright alignjustify | bullist numlist | link media pastetext',
                    plugins: 'media link table lists charmap paste',
                }, this.initEditor);
			} else if (label && (label.includes('Heading'))) {
				return this.$root.copyWith({ 
                    height: 160,
                    toolbar: 'undo redo | fontsizeselect fontselect | bold italic underline | forecolor backcolor removeformat | table | alignleft aligncenter alignright alignjustify | bullist numlist | link media pastetext',
                    plugins: 'media link table lists charmap paste', 
                }, this.initEditor);
			} else {
				return this.$root.copyWith({ 
                    height: 120,
                    toolbar: 'undo redo | fontsizeselect fontselect | bold italic underline | forecolor backcolor removeformat | table | alignleft aligncenter alignright alignjustify | bullist numlist | link media pastetext',
                    plugins: 'media link table lists charmap paste',
                 }, 
                    this.initEditor);
			}
		},
        getImageLib() {
            axios.get("/system/creator/page/library/list")
                .then((response) => {
                    this.pageImageList = response.data;
                });
        },
        showImage(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {

                if (files[0].size > 2097152) {
                this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
                return;
                }

                let imageName = files[0].name;
                if (imageName.lastIndexOf(".") <= 0) {
                return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                this.formItem.imageName = fr.result;
                this.attachment = files[0]; // this is an image file that can be sent to server...
                this.attachmentLabel = files[0].name;
                });
            } else {
                this.attachment = null;
                this.attachmentLabel = "";
            }
        },
        showImageQuestion(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {

                if (files[0].size > 2097152) {
                this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
                return;
                }

                let imageName = files[0].name;
                if (imageName.lastIndexOf(".") <= 0) {
                return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                this.formItem.imageNameQuestion = fr.result;
                this.attachmentQuestion = files[0]; // this is an image file that can be sent to server...
                this.attachmentQuestionLabel = files[0].name;
                });
            } else {
                this.attachmentQuestion = null;
                this.attachmentQuestionLabel = "";
            }
        },
        showImageAnswer1(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {

                if (files[0].size > 2097152) {
                this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
                return;
                }

                let imageName = files[0].name;
                if (imageName.lastIndexOf(".") <= 0) {
                return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                this.formItem.imageNameAnswer1 = fr.result;
                this.attachmentAnswer1 = files[0]; // this is an image file that can be sent to server...
                this.attachmentAnswer1Label = files[0].name;
                });
            } else {
                this.attachmentAnswer1 = null;
                this.attachmentAnswer1Label = "";
            }
        },
        showImageAnswer2(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {

                if (files[0].size > 2097152) {
                this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
                return;
                }

                let imageName = files[0].name;
                if (imageName.lastIndexOf(".") <= 0) {
                return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                this.formItem.imageNameAnswer2 = fr.result;
                this.attachmentAnswer2 = files[0]; // this is an image file that can be sent to server...
                this.attachmentAnswer2Label = files[0].name;
                });
            } else {
                this.attachmentAnswer2 = null;
                this.attachmentAnswer2Label = "";
            }
        },
        showImageAnswer3(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {

                if (files[0].size > 2097152) {
                this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
                return;
                }

                let imageName = files[0].name;
                if (imageName.lastIndexOf(".") <= 0) {
                return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                this.formItem.imageNameAnswer3 = fr.result;
                this.attachmentAnswer3 = files[0]; // this is an image file that can be sent to server...
                this.attachmentAnswer3Label = files[0].name;
                });
            } else {
                this.attachmentAnswer3 = null;
                this.attachmentAnswer3Label = "";
            }
        },
        showImageAnswer4(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {

                if (files[0].size > 2097152) {
                this.$store.commit("showNotification", { title: "Upload error", content: "File size too large, file must be 2MB or lower." });
                return;
                }

                let imageName = files[0].name;
                if (imageName.lastIndexOf(".") <= 0) {
                return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                this.formItem.imageNameAnswer4 = fr.result;
                this.attachmentAnswer4 = files[0]; // this is an image file that can be sent to server...
                this.attachmentAnswer4Label = files[0].name;
                });
            } else {
                this.attachmentAnswer4 = null;
                this.attachmentAnswer4Label = "";
            }
        },
        clearData() {
            this.attachment = null;
            this.attachmentLabel = "";

            this.attachmentQuestion = null;
            this.attachmentQuestionLabel = "";

            this.attachmentAnswer1 = null;
            this.attachmentAnswer1Label = "";

            this.attachmentAnswer2 = null;
            this.attachmentAnswer2Label = "";

            this.attachmentAnswer3 = null;
            this.attachmentAnswer3Label = "";

            this.attachmentAnswer4 = null;
            this.attachmentAnswer4Label = "";

            this.attachment1 = null;
            this.attachment1Label = "";
        },
            
        cancel() {
            this.$store.commit("resetChoosenItem")
            this.$router.back()
        },
        
        async uploadPictureToAWS(image) {
            let result = "";
            let formData = new FormData();
            formData.append("attachment", image);

            await axios.post("/system/creator/tile/upload", formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
            ).then((response) => {
                result = response.data
            })

            return result
        },
        
        
        async save() {

            let module = {...this.$store.state.trainingModule}
            
            if (this.item == "slide") {
                
                //upload image to AWS
                if (this.attachment) {
                    let formData = new FormData();
                    formData.append("attachment", this.attachment);
                    await axios.post("/system/creator/tile/upload", formData).then((response) => {
                        this.formItem.imageName = response.data
                        if (this.operation == "create") {
                            // Create Slide 
                            module.slides.push(this.formItem)
                            this.$store.commit("setTrainingModule", module)
        
                        } else { // edit
        
                            // replace slide with new slide
                            module.slides = module.slides.map((s, index) => 
                                (s.heading + "-" + index)  != this.$store.state.choosenItem.previousId ? s :
                                this.formItem
                            );

        
                            this.$store.commit("setTrainingModule", module)
                        }
                    })
                } else {
                    if (this.operation == "create") {
                        // Create Slide 
                        module.slides.push(this.formItem)
                        this.$store.commit("setTrainingModule", module)
    
                    } else { // edit
    
                        // replace slide with new slide
                        module.slides = module.slides.map((s, index) => 
                            (s.heading + "-" + index) != this.$store.state.choosenItem.previousId ? s :
                            this.formItem
                        );
    
                        this.$store.commit("setTrainingModule", module)
                    }
                }

            } else {    // quiz

                // TODO: upload image to AWS
                if (this.attachmentQuestion || this.attachmentAnswer1 || this.attachmentAnswer2 || this.attachmentAnswer3 || this.attachmentAnswer4) {
                    
                    if (this.attachmentQuestion) {
                        let formData = new FormData();
                        formData.append("attachment", this.attachmentQuestion);
    
                        await axios.post("/system/creator/tile/upload", formData,
                        {
                            headers: {
                                "Content-Type": "multipart/form-data"
                            }
                        }
                        ).then((response) => {
                            this.formItem.imageNameQuestion = response.data
                        })
                    }

                    if (this.attachmentAnswer1) {
                        this.formItem.imageNameAnswer1 = await this.uploadPictureToAWS(this.attachmentAnswer1);
                    }
                    if (this.attachmentAnswer2) {
                        this.formItem.imageNameAnswer2 = await this.uploadPictureToAWS(this.attachmentAnswer2);
                    }
                    if (this.attachmentAnswer3) {
                        this.formItem.imageNameAnswer3 = await this.uploadPictureToAWS(this.attachmentAnswer3);
                    }
                    if (this.attachmentAnswer4) {
                        this.formItem.imageNameAnswer4 = await this.uploadPictureToAWS(this.attachmentAnswer4);
                    }

                    if (this.operation == "create") {
                            // Create Quiz 
                            module.quizes.push(this.formItem)
                            this.$store.commit("setTrainingModule", module)
        
                    } else { // edit
    
                        // replace quiz with new quiz
                        module.quizes = module.quizes.map((s, index) => 
                            (s.question + "-" + index) != this.$store.state.choosenItem.previousId ? s :
                            this.formItem
                        );
    
                        this.$store.commit("setTrainingModule", module)
                    }

                } else {
                    if (this.operation == "create") {
                    // Create Quiz 
                    module.quizes.push(this.formItem)
                    this.$store.commit("setTrainingModule", module)

                    } else { // edit

                        // replace quize with new quiz
                        module.quizes = module.quizes.map((s, index) => 
                            (s.question + "-" + index) != this.$store.state.choosenItem.previousId ? s :
                            this.formItem
                        );

                        this.$store.commit("setTrainingModule", module)
                    }

                }
                
            }
            
            this.$store.commit("resetChoosenItem")

            // back to previous
            this.$router.back()

        },
        onSelectImage(item) {
            let result = this.$bucket + '/' + item;
            if (this.dialogLibrary.target == 'hero') {
                this.formItem.imageName = result
            } else if (this.dialogLibrary.target == "question") {
                this.formItem.imageNameQuestion = result
            } else if (this.dialogLibrary.target == "answer1") {
                this.formItem.imageNameAnswer1 = result
            } else if (this.dialogLibrary.target == "answer2") {
                this.formItem.imageNameAnswer2 = result
            } else if (this.dialogLibrary.target == "answer3") {
                this.formItem.imageNameAnswer3 = result
            } else if (this.dialogLibrary.target == "answer4") {
                this.formItem.imageNameAnswer4 = result
            }
            // else if (this.dialogLibrary.target == 'background') {
            //     this.entity.backgroundImage = result;
            // } else if (this.dialogLibrary.target.startsWith('image')) {
            //     this.entity.map.contentList[this.dialogLibrary.idx].content[this.dialogLibrary.target] = result;
            //     this.$forceUpdate();
            // }

            this.dialogLibrary.show = false;
        },
        showImageLib(target, idx = undefined) {

            // Before commit , please remove outter IF
            // if (target == 'question') {
            //     this.formItem.imageNameQuestion = "https://airnzduo-stage.s3-ap-southeast-2.amazonaws.com/image/dashboard-pages/Auckland Hero Mobile.jpg"
            // } else if (target == 'answer1') {
            //     this.formItem.imageNameAnswer1 = "https://airnzduo-stage.s3-ap-southeast-2.amazonaws.com/image/dashboard-pages/Auckland Hero Mobile.jpg"
            // } else if (target == 'answer2') {
            //     this.formItem.imageNameAnswer2 = "https://airnzduo-stage.s3-ap-southeast-2.amazonaws.com/image/dashboard-pages/Auckland Hero Mobile.jpg"
            // } else if (target == 'answer3') {
            //     this.formItem.imageNameAnswer3 = "https://airnzduo-stage.s3-ap-southeast-2.amazonaws.com/image/dashboard-pages/Auckland Hero Mobile.jpg"
            // } else if (target == 'answer4') {
            //     this.formItem.imageNameAnswer4 = "https://airnzduo-stage.s3-ap-southeast-2.amazonaws.com/image/dashboard-pages/Auckland Hero Mobile.jpg"
            // }
            // else {
                if (target == 'background') {
                    this.dialogLibrary.list = this.backgroundImageList;
                } else {
                    this.dialogLibrary.list = this.pageImageList;
                }
    
                this.dialogLibrary.show = true;
                this.dialogLibrary.target = target;
                this.dialogLibrary.idx = idx;
            // }
        },
        openFileDialog() {
            this.$refs.attachment.click();
        },
        openFileQuestionDialog() {
            this.$refs.attachmentQuestion.click();
        },
        openFileAnswer1Dialog() {
            this.$refs.attachmentAnswer1.click();
        },
        openFileAnswer2Dialog() {
            this.$refs.attachmentAnswer2.click();
        },
        openFileAnswer3Dialog() {
            this.$refs.attachmentAnswer3.click();
        },
        openFileAnswer4Dialog() {
            this.$refs.attachmentAnswer4.click();
        },
        refresh() {
            this.$forceUpdate();
        },
        showPreview() {
            this.page.valueMap.headerTitle = this.formItem.heading
            this.page.valueMap.bodyCopy = this.formItem.bodyCopy
            this.page.valueMap.buttonText = this.formItem.buttonText
            this.page.valueMap.imageName = this.formItem.imageName
            this.page.valueMap.backgroundColor = this.formItem.backgroundColor
            this.page.type = this.formItem.type
            this.page.title = this.formItem.title

            this.page.valueMap.question = this.formItem.question
            this.page.valueMap.imageNameQuestion = this.formItem.imageNameQuestion
            this.page.valueMap.answer1 = this.formItem.answer1
            this.page.valueMap.answer2 = this.formItem.answer2
            this.page.valueMap.answer3 = this.formItem.answer3
            this.page.valueMap.answer4 = this.formItem.answer4
            this.page.valueMap.imageNameAnswer1 = this.formItem.imageNameAnswer1
            this.page.valueMap.imageNameAnswer2 = this.formItem.imageNameAnswer2
            this.page.valueMap.imageNameAnswer3 = this.formItem.imageNameAnswer3
            this.page.valueMap.imageNameAnswer4 = this.formItem.imageNameAnswer4

            this.page.valueMap.correctAnswer = this.formItem.correctAnswer

            this.dialogPreview = true;
        },
        closePreview() {
			this.dialogPreview = false
            this.isCorrect = false
            this.isSelected = false
		},
        changeBgColor(color) {
            this.formItem.backgroundColor = color
        }
    }
}
</script>

<style>
.circle-black {
  border: 1px solid white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #474746;
}
.circle-white {
  border: 1px solid white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: white;
}
.circle-purple {
  border: 1px solid white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #221551;
}
</style>