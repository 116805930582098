<template>
  <v-container fluid>
    <subHeader />
    <!-- {{ enableButton() }} -->
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            min-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-tiles/SingaporeTen.LandingHero.jpeg'"
          />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2>
            We are proud to be celebrating 10 years of partnership with Singapore Airlines, providing our customers with seamless connections to Singapore and beyond.
          </h2>
          <p>
            <b>To celebrate this milestone in our partnership, we are giving away two pairs of return flights to Singapore.</b>
          </p>
          <div>
            <span style="font-size:17px">The winning two agents will win either:</span>
            <br><br>
            <ul>
              <li>Return economy tickets for them and a friend on Air New Zealand operated services to Singapore</li>
              <li>Return economy tickers for them and a friend on Singapore Airlines operated services to Singapore</li>
            </ul>
          </div>
          <br><br>
          <div style="background-color:#EEEEEE; margin-bottom:1.5vw; padding:1.5vw;">
            <span style="font-size:17px">To win, agents must:</span>
            <br><br>
            <ul>
              <li>
                Book, and ticket Air New Zealand or Singapore Airlines flights on our JV network between Monday 17th March – Monday 31st March 2025, for travel anytime
              </li>
              <li style="margin-top: 10px;">
                Agents must register their Air New Zealand (086 tickets) booking references through Duosales
              </li>
              <li style="margin-top: 10px;">
                Agents must register their Singapore Airlines (618 tickets) booking references through Singapore Airlines Agent360 portal
              </li>
              <li style="margin-top: 10px;">
                Complete the new Air New Zealand and Singapore Airlines Alliance training module on either duo or Agent360
              </li>
              <li style="margin-top: 10px;">
                Each Economy PNR/Booking reference logged will earn the participant one entry in the draw
              </li>
              <li style="margin-top: 10px;">
                Each Premium Economy PNR/Booking reference logged will earn the participant two entries in the draw
              </li>
              <li style="margin-top: 10px;">
                Each Business Class PNR/Booking reference logged will earn the participant three entries in the draw
              </li>
              <li style="margin-top: 10px;">
                Bookings logged on behalf of another agent are ineligible for entry
              </li>
              <li style="margin-top: 10px;">
                Entry is open to all our Retail, Leisure, Wholesale and Corporate Agents
              </li>
            </ul>
            <br>
            <b>The more points you earn, the more entries into the draw you have!</b>
            <p style="font-size: 13px">
              <a
                style="font-size: 13px; color: black; font-style: italic"
                :href="$window.location.origin + '/#/page/AirNewZealandandSingaporeAirlinestermsandconditions'"
                target="_blank"
                >Terms and conditions apply.</a
              >
            </p>
          </div>
          <div>
            <p>
              <b>But don't worry, to celebrate 10 years together, we also have 10 daily prizes to giveaway! Between Monday 17th March and Friday 28th March 2025, there will be a new daily question to answer each business day. Get it right, and you're in the draw to win a selection of prizes from Air New Zealand and Singapore Airlines!</b>
            </p>
          </div> 
          <div style="overflow: hidden; height: 700px;">
            <iframe 
              :src= "'https://xd.wayin.com/display/container/dc/' + currentSrc" 
              style="transform: translateY(-100px)"
              frameborder="0" 
              scrolling="no" 
              width="100%" 
              :id="'ngxFrame' + currentSrc"  
              allowTransparency="true">
            </iframe>
          </div>
          <br>
          <!--  -->
              <v-row>
                <v-col
                  v-for="tile in 3"
                  :key="tile.title"
                  cols="4"
                  md="4"
                  sm="12"
                >
                  <v-card tile elevation="0">
                    <v-img
                      :src="$bucket + '/image/dashboard-tiles/SingaporeTen.Image'+ (tile)+'.jpeg'"
                    ></v-img>
                  </v-card>
                </v-col>
              </v-row>
          <!--  -->
          <br>
          <a href="https://duo.airnzagent.co.nz/#/administration/training/edit/module" target="_blank">
            <v-img
              max-width="1266"
              min-height="345"
              style="background-size: cover; background-position: center center"
              :src="$bucket + '/image/dashboard-tiles/Singapore10.TrainingTile.jpeg'"
            />
          </a>

          
        </div>
      </v-row>
      <br />
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    this.updateIframeSrc(); // Set initial src
    this.checkAndUpdateTime(); // Start checking for updates
  },
  data() {
    return {
      currentSrc: "a661a42f-8192-4354-9c7e-11735e676b36",
      startDate: new Date("2025-03-17T06:00:00+13:00"), // Start time in NZT (UTC+13)
      endDate: new Date("2025-03-30T23:59:59+13:00"), // End time in NZT
      wayinsrc: [
        "a661a42f-8192-4354-9c7e-11735e676b36",
        "80d25c3a-0611-4424-babf-3a11b7a9baff",
        "424a576b-5e88-4411-8938-2fbd61ef05e8",
        "b78c117c-a0de-4373-8bcb-84b9633240b2",
        "a5edce44-d98e-43ee-9965-d393bb6ef459",

        //saturday
        "a5edce44-d98e-43ee-9965-d393bb6ef459",
        //sunday
        "a5edce44-d98e-43ee-9965-d393bb6ef459",

        "b31d65bf-09d0-4f17-a623-71159b5a1654",
        "461c7266-8e1b-4785-9376-f15843037e46",
        "2fe3c8f3-410b-4861-bcd6-c652dfef09ef",
        "df5b0945-ee2b-4663-9f90-eab0947ae630",
        "bac5135e-40c0-460e-9d9d-27750625daf5",
      ]
    };
  },
  methods: {
    updateIframeSrc() {
      const now = new Date();
      const nowNZT = new Date(now.toLocaleString("en-US", { timeZone: "Pacific/Auckland" })); // Convert to NZT

      // Check if we're within the allowed date range
      if (nowNZT < this.startDate || nowNZT > this.endDate) {
        return;
      }

      const dayIndex = Math.floor((nowNZT - this.startDate) / (1000 * 60 * 60 * 24)); // Days since start

      this.currentSrc = this.wayinsrc[dayIndex % this.wayinsrc.length];
    },
    checkAndUpdateTime() {
      setInterval(() => {
        const now = new Date();
        const nowNZT = new Date(now.toLocaleString("en-US", { timeZone: "Pacific/Auckland" })); // Convert to NZT

        if (nowNZT.getHours() === 6 && nowNZT.getMinutes() === 0) {
          this.updateIframeSrc();
        }
      }, 60000); // Check every minute
    }
  }
};
</script>
